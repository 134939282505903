

.form
  display: flex
  flex-wrap: wrap
  & > *
    width: calc(50% - 24px)
    margin-top: 18px
    &:nth-child(even)
      margin-left: 24px
  input
    width: 100%
  button[type="submit"]
    margin-top: 24px
    margin-left: 0
  .change-password
    width: 100%
    display: flex
    margin-left: 0
    align-items: flex-end
    margin-top: 40px
    margin-bottom: 24px
    button
      align-self: flex-end
      margin-left: 16px
    .response
      margin-bottom: 12px
  
.title
  color: #392964
  font-weight: 400



.items
  display: flex
  margin-top: 24px
  & > *
    &:not(:first-child)
      margin-left: 24px
    .icon
      margin-bottom: 32px
      margin-top: 32px
      font-size: 32px
      display: flex
      align-items: center
      svg
        width: 40px
        height: 40px
        margin-right: 20px
        *
          fill: #8250FF
    a
      color: #8250FF
      text-decoration: none
      svg
        position: relative
        top: 2px


.loading-indicator {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 12px solid #392964;
  border-top-color: transparent;
  animation: loading-indicator .75s infinite;
}

@keyframes loading-indicator {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}
.mainDiv
    background-color: transparent
    display: flex

.divRight  
    width: 60%
    
.divLeft  
    width: 40%
    display: flex
    justify-content: center
    align-items: center
    height: 400px !important

.form 
    padding: 10px

.TextField
    width: 90%
    margin-bottom: 20px !important

.TextField2
    width: 90%
    margin-bottom: 20px !important

.formBtn
    width: 90%
    margin-top: 15px !important

.successDiv 
    display: flex
    align-items: center
    justify-content: center

.successDiv2
    display: flex
    flex-direction: column
    align-items: center
    padding-bottom: 28px

    h3
        margin: 0
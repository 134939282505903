$gray: #dadada

table.table-list
  width: 100%
  table-layout: auto
  border-collapse: unset
  border-spacing: unset
  overflow: hidden
  height: 128px
  tr
    th:first-child
      border-top-left-radius: 12px
      border-bottom-left-radius: 12px
    th:last-child
      border-top-right-radius: 12px
      border-bottom-right-radius: 12px
    td:first-child
      border-top-left-radius: 12px
      border-bottom-left-radius: 12px
    td:last-child
      border-top-right-radius: 12px
      border-bottom-right-radius: 12px
  tbody
    tr
      border-top: 16px solid transparent
  td, th
    padding: 24px
    text-align: start
    font-size: 14px
  tbody
    tr
      transition: .15s
      &:hover
        background: #392964
        color: #fff
      &.clickable
        cursor: pointer
  td
    &.controls-container
      width: 48px
      padding: 0
    .controls
      width: 64px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
    &.transformed
      padding: 0
    &.clickable
      transition: .15s
      cursor: pointer
      &:hover
        background-color: #ff652891
        color: #fff
  tr:nth-child(even)
    background: #F9F8FB
  th
    background: #eff2f5
    &.sortable
      position: relative
      user-select: none
      &:hover
        background: #3929640e
        cursor: pointer
    .sort-icon
      position: absolute
      top: 50%
      right: 8px
      transition: .3s
      transform: translateY(-50%)
      &.desc
        transform: translateY(-50%) rotate(180deg)

.table-list-query
  border-radius: 8px
  padding: 16px
  background: #fff
  overflow: hidden
  width: 100%
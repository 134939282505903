
.separated-words-input.transparent
  border-bottom: 2px solid rgba(242, 101, 41, .3)
  color: #f26529
  font-size: 30px
  padding-bottom: 8px
  span
    font-size: 22px
  .placeholder
    color: rgba(242, 101, 41, .3)

.separated-words-input.standard
  padding: 12px 20px
  border: 1px solid #d8d5de
  background: #f9f8fb
  border-radius: 4px

.separated-words-input
  display: flex
  flex-wrap: wrap
  position: relative
  .word-input
    background: none
    border: none
    outline: none
    margin-left: 12px
    width: auto !important
  &:not(.transparent) .word-input
    height: 24px
  span
    padding: 4px 8px
    padding-right: 32px
    display: inline-block
    border: 1px solid #dfdfdf
    border-radius: 32px
    position: relative
    & + span
      margin-left: 12px
    svg
      position: absolute
      top: 50%
      right: 8px
      font-size: 20px
      transform: translateY(-50%)
      cursor: pointer
  .placeholder
    position: absolute
    pointer-events: none
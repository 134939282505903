

.container
  display: flex
  padding: 16px
  margin: -12px
  border-radius: 4px

.button-container
  height: 48px
  display: flex
  justify-content: flex-end
  button
    margin: 0
    width: auto !important

.flex
  display: flex
  justify-content: space-between
  margin-bottom: 30px

.assessment-model
  background: #fff
  padding: 24px 16px 16px
  border-radius: 32px
  margin: -7px
  display: flex
  flex-wrap: wrap
  width: 150%
  .title
    width: 100%
    color: #392B63
    span
      color: #8250FF
  .model-attribute
    width: calc(33% - 28px)
    margin: 7px
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)
    border-radius: 8px
    padding: 20px 16px 40px
    font-family: 'Open Sans', sans-serif
    p
      margin: 0
    .title
      margin-bottom: 8px
      display: flex
      align-items: center
    .description
      color: #6B7280
      font-size: 14px
      line-height: 20px
    img
      height: 40px
    span
      color: #8250FF
      font-size: 18px
      line-height: 24px
      margin-left: 8px
      font-weight: 600

.assessment-model1
  filter: blur(4px)
  background: #fff
  padding: 24px 16px 16px
  border-radius: 32px
  margin: -7px
  display: flex
  flex-wrap: wrap
  width: 150%
  .model-attribute
    width: calc(33% - 28px)
    margin: 7px
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)
    border-radius: 8px
    padding: 20px 16px 40px
    font-family: 'Open Sans', sans-serif
    p
      margin: 0
    .title
      margin-bottom: 8px
      display: flex
      align-items: center
    .description
      color: #6B7280
      font-size: 14px
      line-height: 20px
    img
      height: 40px
    span
      color: #8250FF
      font-size: 18px
      line-height: 24px
      margin-left: 8px
      font-weight: 600


.span
  margin-left:10px
  width: 6rem
  button
   width: 6rem
   height: 3rem
   background-color: #8250FF
   color: #FFFFFF
   padding: 10px 20px
   border: none
   border-radius: 4px
   cursor: pointer
  button:hover
   background-color: #6B3FDB



.custom-select 
  height:3rem
  margin:0px 10px
  position: relative
  display: inline-block
  margin-top: 5px
  width: 100px 


.select-input 
  appearance: none
  padding:0px 10px
  border: 1px solid #ccc
  border-radius: 4px
  background-color: white
  width: 100%
  cursor: pointer
  padding-right: 20px
  background: url('../../../assets/img/downArrow.png') no-repeat center right 6px
  background-size: 20px 20px
  vertical-align: middle

.select-input option 
  background-color: white
  color: black


.select-input option:hover 
  background-color: #f5f5f5


.select-input option:checked 
  background-color: #e0e0e0
  

.fit-tech-banner
  background: #ff6428
  border-radius: 8px
  margin-top: 80px
  color: #fff
  font-family: 'Open Sans', sans-serif
  margin-left: -12px
  margin-right: -12px
  padding: 26px 40px 40px
  position: relative
  width: 50%
  h1
    font-weight: normal
    font-size: 32px
    line-height: 35px
    span
      font-weight: 700
  .checkbox
    width: 70% !important
    label
      cursor: pointer
    &-view
      width: 16px
      height: 16px
      background: #fff
      border-radius: 2px
      display: inline-flex
      margin-right: 12px
      margin-top: 12px
      position: relative
      transition: .1s
      align-items: center
      justify-content: center
      svg
        opacity: 0
        transition: .1s
    input:checked + .checkbox-view 
      background: #FC6535
      svg
        opacity: 1
  .character
    position: absolute
    right: 15px
    bottom: -15px
  .thumb
    position: absolute
    right: 300px
    top: 75px
  &.shake
    animation: shake .5
  .trial-message
    margin-top: 12px
    margin-bottom: 8px

.overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: #0005
  display: flex
  justify-content: center
  align-items: center
  .modal
    outline: none
    width: 500px
    background: #fff
    padding: 16px
    border-radius: 8px
    position: relative
    .modal-content
      display: flex
      flex-direction: column
      // align-items: center
      .close
        position: absolute
        right: 12px
        top: 12px
        border-radius: 50%
        width: 32px
        height: 32px
        display: flex
        align-items: center
        justify-content: center
        transition: .15s
        cursor: pointer
        &:hover
          background: #ededed
        svg
          width: 20px
          height: 20px
      h2
        color: #8250FF
        font-weight: 600
        font-size: 16px
        line-height: 22px
      ul
        list-style: none
        margin: 0
        padding: 0
        li
          display: flex
          align-items: center
          justify-content: space-between
          background: #ededed
          padding: 13px 16px
          border-radius: 8px
          & + li
            margin-top: 12px
          button
            width: auto !important
            margin: 0
          span + span
            margin-left: 32px


.ask-question
  margin-top: 32px
  font-size: 18px
  a
    color: #8250FF
    font-weight: 700



.container2
  background: #fff
  padding: 24px 16px 16px
  border-radius: 32px
  margin: -7px
  display: flex
  flex-wrap: wrap
  align-items: center
  width: 50%
  margin-left: 3%
  .table
    width: 70%
  .banner
    width: 100%
    height: 100%
    .submissions-count
      display: flex
      div:first-child
        margin-right: 10px
      p
        margin: 0
      .count
        color: #EEEEEE
        span
          font-size: 1.4em
          color: #fff
    .cta
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      height: 100%
      text-align: center
      padding: 12px 12px 0px 12px
      position: relative
      padding-bottom: 0 px
      h1
        font-weight: normal
      p
        line-height: 1.5
      svg
        position: absolute
        left: 50%
        transform: translateX(-50%)
      
    svg.no-submissions-arrow
      position: absolute
      top: auto
      left: auto
      right: 100%
      bottom: -14px



.no-submissions
  height: 400px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  h1
    font-weight: 600
    font-size: 28px
    line-height: 150%
    color: #6151D1
    margin: 0
  p
    color: #0A181F
    font-weight: 400
    font-size: 15px
    line-height: 150%
    width: 300px
    text-align: center
  svg
    position: absolute
    bottom: 10%
    right: 0

.box
  background: #fff
  border-radius: 12px
  color: #fff
  height: 100%
  & + &
    margin-top: 12px
  & > *:not(:last-child)
    margin-bottom: 12px !important

.submissions
  width: 100%
 

.Nosubmissions
  color: #000
  font-size: 4rem
  font-weight: 600
  margin-top: 3%
  margin-bottom: 0

.submissionsTexr
  color: #000
  font-size: 1.75rem
  font-weight: 800
  margin-top: 0
  margin-bottom: 0

.viewsubmissions
  color: #8b8b8b
  display: flex
  align-items: center
  justify-content: center
  font-size: 0.875rem

.viewsubmissions_icon
  position: unset !important 

.viewsubmissions_p
  margin: 0

.share-link-container
  display: flex
  align-items: center
  justify-content: center

.download-icon 
  vertical-align: middle
  font-size: 120%
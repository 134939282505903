

.navigation-panel
  width: 250px 
  position: relative
  height: 96%
  overflow-y: auto
  & > img
    width: 140px
    display: block
    margin: 12px auto 40px
  ul
    list-style: none
    margin: 0
    padding: 0
    li
      color: #8D8797
      a
        color: inherit
        text-decoration: none
        display: flex
        justify-content: flex-start
        align-items: center 
        padding: 14px 10px 
        padding-left: 30px
        transition: .15s
        cursor: pointer
        svg
          margin-right: 8px
          &:not(.ri) *
            fill: #8d8797
        &:hover, &.active
          fill: #fff !important
          padding: 16px 13px
          background: #fff
          color: #222
          svg:not(.ri) *
            fill: #fff
          svg.ri
            color: #1963da !important
        &.active 
            background: #8250ff  !important
            color: #fff !important 
            font-weight: 600
            width: 98%
            display: flex
            margin: 3px auto
            border-radius: 10px
            padding: 16px 13px
        &:hover
          background: #8250ff  !important
          color: #fff !important 
          font-weight: 600
          width: 98%
          display: flex 
          border-radius: 10px
          padding: 16px 13px
          margin: 3px auto

      a.sub-item
        &:hover
          color: #8250ff  !important
          background: #fff !important 
          svg
            &:not(.ri) *
              fill: #8250ff
        &.active 
          color: #8250ff  !important
          background: #fff !important 
          svg
            &:not(.ri) *
              fill: #8250ff
        padding-left: 48px

.navigation-panel.touch
  width: 80vw
.Tools
  margin: 7px 18px
  font-size: 14px
  color: #90969c



.navigation-panel::-webkit-scrollbar 
  width: 2px


.navigation-panel::-webkit-scrollbar-track 
  background: #f1f1f1

 
.navigation-panel::-webkit-scrollbar-thumb 
  background: #888


.navigation-panel::-webkit-scrollbar-thumb:hover 
  background: #555

.disabled
  opacity: 0.5
  cursor: not-allowed
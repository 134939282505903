
.container
  display: flex
  justify-content: space-between
  flex-direction: column

.container2
  display: flex
  width: 100%
  justify-content: space-between
  background: #fff
  border-radius: 10px
  padding: 15px
  flex-direction: column

.headerText
  color: #8250FF
  font-size: 25px
  font-weight: 600
  
.description
  width: 93%
  margin: 0

.stepsDiv
  width: 100% 
  display: flex
  padding: 10px
  margin-top: 30px
  justify-content: space-between
  flex-direction: column

.stepsub
  display: flex
  align-items: center

.span
  width: 12px
  height: 12px
  border-radius : 99px
  background: #8250FF
  margin: 0px 5px

.step
  width: 70%
  display: flex 
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin-bottom: 30px

.StepH
  font-size: 18px
  color: #8250FF
  font-weight: 500
  margin: 0px

.btn
  border: 0
  padding: 5px 20px
  background: #8250FF
  border-radius: 7px
  color: #fff
  width: 25%
  height: 70%
  cursor: pointer
  text-align: center
  text-decoration: none

.btn:hover
  background: #9a75f5



.file-input
  label
    border: 1px dashed #f26529
    padding: 16px 12px
    position: initial !important
    display: block
    border-radius: 20px
    max-width: 400px !important
    text-align: center
    color: lighten(#f26529, 10%) !important
    cursor: pointer
    transition: .15s
    &:hover
      background: lighten(#f26529, 43%)
  &.has-file
    label
      border-style: solid


.header
  display: flex
  justify-content: space-between
  margin-bottom: 32px
  align-items: center
  padding: 12px
  h1
    color: #8D8797
    font-weight: normal
  button
    width: 150px !important
  

.user-data
  display: flex
  list-style: none
  padding: 0
  flex-direction: column
  padding: 0 12px
  li
    display: flex
    padding: 16px 4px
    border-top: 1px solid #dee2e6
    &:last-child
      border-bottom: 1px solid #dee2e6
    span
      width: 50%

.container
  display: flex
  justify-content: space-between
  flex-direction: column
  .table
    width: 100%
  .banner
    width: 25%
    .submissions-count
      display: flex
      div:first-child
        margin-right: 10px
      p
        margin: 0
      .count
        color: #EEEEEE
        span
          font-size: 1.4em
          color: #fff
    .cta
      text-align: center
      padding: 12px 12px 0px 12px
      position: relative
      padding-bottom: 0 px
      h1
        font-weight: normal
      p
        line-height: 1.5
      svg
        position: absolute
        left: 50%
        transform: translateX(-50%)
        bottom: -30px
      
    svg.no-submissions-arrow
      position: absolute
      top: auto
      left: auto
      right: 100%
      bottom: -14px

.container2
  display: flex
  width: 100%
  justify-content: space-between

.no-submissions
  height: 400px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  h1
    font-weight: 600
    font-size: 28px
    line-height: 150%
    color: #6151D1
    margin: 0
  p
    color: #0A181F
    font-weight: 400
    font-size: 15px
    line-height: 150%
    width: 300px
    text-align: center
  svg
    position: absolute
    bottom: 10%
    right: 0

.box
  background: #fff
  border-radius: 12px
  padding: 12px
  color: #fff
  & + &
    margin-top: 12px
  & > *:not(:last-child)
    margin-bottom: 12px !important

.submissions
 

.Nosubmissions
  color: #000
  font-size: 40px
  font-weight: 600
  margin-bottom: 0

.submissionsTexr
  color: #000
  font-size: 25px
  font-weight: 800
  margin-top: 0

.viewsubmissions
  color: #8b8b8b
  display: flex
  align-items: center
  justify-content: center

.viewsubmissions_icon
  position: unset !important 

.viewsubmissions_p
  margin: 0

.share-link-container
  display: flex
.share-link
    display: flex
    padding: 10px 16px
    background: #F3F4F7
    margin-left: 15px
    border: 1px solid #A8B5C2
    border-radius: 8px
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: #BCBDBF
    width: 420px
    justify-content: space-between
    align-items: center
    .display
      cursor: pointer
      transition: .15s
      &:hover
        color: #8250ff
    .link
      margin-right: 12px
      max-width: 100%
      overflow: auto
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none  /* IE and Edge */
      scrollbar-width: none  /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar
        display: none    
    .copy
      color: #8250ff
      display: flex
      align-items: center
      cursor: pointer
      transition: .15s
      padding: 4px 8px
      border-radius: 8px
      svg
        margin-left: 8px
      &:hover
        background: #8250ff
        color: #fff

.container22
  display: flex
  padding: 16px 
  border-radius: 4px

.assessment-model
  background: #fff
  padding: 24px 16px 16px
  border-radius: 32px
  margin: -7px
  display: flex
  flex-wrap: wrap
  width: 150%
  .title
    width: 100%
    color: #392B63
    span
      color: #8250FF
  .model-attribute
    width: calc(33% - 28px)
    margin: 7px
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)
    border-radius: 8px
    padding: 20px 16px 40px
    font-family: 'Open Sans', sans-serif
    p
      margin: 0
    .title
      margin-bottom: 8px
      display: flex
      align-items: center
    .description
      color: #6B7280
      font-size: 14px
      line-height: 20px
    img
      height: 40px
    span
      color: #8250FF
      font-size: 18px
      line-height: 24px
      margin-left: 8px
      font-weight: 600

.containerModal
  background: #fff
  padding: 24px 16px 16px
  border-radius: 32px
  margin: -7px
  display: flex
  flex-wrap: wrap
  align-items: center
  width: 50%
  margin-left: 3%
  .table
    width: 70%
  .banner
    width: 100%
    height: 100%
    .submissions-count
      display: flex
      div:first-child
        margin-right: 10px
      p
        margin: 0
      .count
        color: #EEEEEE
        span
          font-size: 1.4em
          color: #fff
    .cta
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      height: 100%
      text-align: center
      padding: 12px 12px 0px 12px
      position: relative
      padding-bottom: 0 px
      h1
        font-weight: normal
      p
        line-height: 1.5
      svg
        position: absolute
        left: 50%
        transform: translateX(-50%)
      
    svg.no-submissions-arrow
      position: absolute
      top: auto
      left: auto
      right: 100%
      bottom: -14px


.modal 
  display: block
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)


.modal-content
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  background: white
  padding: 20px
  border-radius: 5px


.close 
  position: absolute
  top: 10px
  right: 10px
  font-size: 20px
  cursor: pointer


.survey
  position: relative
  overflow: hidden
  h1
    font-weight: 400
  .navigation
    position: absolute
    width: 300px
    z-index: 2
    display: flex
    right: 0
    bottom: 0
    .progress
      border-radius: 4px
      background: #fff
      padding: 4px
      width: 100%
      p
        margin: 0
        font-size: 12px
        color: rgba(98, 36, 255, .7)
      .bar
        background: rgba(98, 36, 255, .1)
        height: 4px
        margin-top: 2px
        span
          background: #6224FF
          height: 100%
          display: block
          transition: .15s
    .next, .prev
      background: #f26529
      padding: 4px
      border-radius: 4px
      color: #333
      font-size: 36px
      min-width: 32px
      min-height: 32px
      margin-left: 4px
      position: relative
      cursor: pointer
      transition: .15s
      &:hover
        background: lighten(#f26529, 5% )
        color: #fff
      svg
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
  .background-img
    position: absolute
    right: 40px
    bottom: -20px
    opacity: 0.3
    z-index: 0
    width: 25%
  form
    display: flex
    flex-direction: column
    padding: 20px 200px
    justify-content: flex-start
    max-height: 70vh
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    position: relative
    z-index: 1
    &::-webkit-scrollbar
      display: none
    .form-description
      margin-bottom: 30px
      h2
        font-size: 24px
        font-weight: bold
        line-height: 32px
        color: #6224FF
        text-align: center
      p
        font-size: 20px
        line-height: 28px
        color: rgba(98, 36, 255, .7)
        text-align: center
        &:not(:first-child)
          margin-top: 32px
    .question
      min-height: 70vh
      display: flex
      flex-direction: column
      justify-content: center
      outline: none
      &.fade
        opacity: 0.3
      label
        font-size: 24px
        line-height: 32px
        color: #6224ff
        position: relative
        margin-bottom: 40px
        max-width: 100%
        span
          position: absolute
          top: 0
          left: -40px
          color: #f26529
          font-size: 16px
          transition: .15s
          &.three-digit
            left: -54px
          svg
            position: relative
            top: 2px
      &.current
        & > label span
          transform: translateX(-20px)
          // font-weight: bold
          // text-shadow: 0 0 15px #f26529

.firstComma 
  font-family: 'Open Sans, Segoe UI Bold, Tahoma, Geneva, Verdana, sans-serif'
  display: inline-block
  font-size: 2.7rem


.secondComma 
  font-family: 'Open Sans, Segoe UI Bold, Tahoma, Geneva, Verdana, sans-serif'
  font-size: 2.7rem
  display: inline-block
  margin-left: -6px
  margin-right: 10px

.pressEnter
  color: #6224ff
  margin-left: 12px
  margin-top: 12px
  font-size: 0.9rem

.pressEnterContainer 
  display: flex
  align-items: center

.pressEnterImg 
  width: 15px
  height: 15px
  margin-top: 1px
  margin-left: 5px

@media (max-width: 1200px)
  .get-started-section
    form
      padding: 20px 80px
    
@media (max-width: 420px)
  .survey
    .background-img
      bottom: 160px
    form
      padding: 20px 16px
      padding-bottom: 200px
      input
        font-size: 20px !important
      .question
        label
          span
            position: static
            margin-right: 8px
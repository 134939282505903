.pagination-container
  display: flex
  align-items: center
  justify-content: space-between
  margin: 24px -16px 0
  border-top: 1px solid #E5E7EB
  padding: 0 24px
  .pagination
    list-style: none
    display: flex
    padding: 0
    margin: 0
    width: 100%
    justify-content: space-between
    color: #6B7280
    position: relative
    bottom: 1px
    .next, .previous
      width: 100px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      position: relative
      span
        position: relative
        bottom: 3px
      svg
        position: absolute
        top: 50%
        left: 0
        transform: translateY(-50%)
        transition: .15s
      &:hover:not(.disabled) 
        color: #FC6535
        svg
          fill: #FC6535
      
    .next
      margin-left: auto
      a span
        margin-right: 24px
      svg
        right: 0
        left: auto
        transform: translateY(-50%) rotate(180deg)
    .previous
      margin-right: auto
      a span 
        margin-left: 24px
    li
      width: 34px
      height: 34px
      border-top: 2px solid transparent
      padding-top: 12px
      & + li
        margin-left: 8px
      &.selected
        border-color: #FC6535
        a
          color: #FC6535
      a
        display: block
        width: 100%
        height: 100%
        border-radius: 2px
        line-height: 24px
        font-size: 16px
        padding: 4px 0
        text-align: center
        cursor: pointer
        transition: .15s
        user-select: none
        position: relative
        &:hover
          background: var(--mainTransparent)
          color: var(--main)
  .pagination-per-page
    div
      display: inline-block
    span:not(.per-page)
      font-size: 13px
      color: var(--textColor)
      margin-right: 16px
    span.per-page
      padding: 4px 8px
      border: 2px solid var(--textColorLight)
      font-size: 16px
      line-height: 24px
      cursor: pointer
      transition: .15s
      &:first-child
        border-radius: var(--borderRadius) 0 0 var(--borderRadius)
        padding-left: 10px
      &:last-child
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0 
        padding-right: 10px
      &[data-selected=true]
        border-color: var(--mainLight)
        color: var(--main)
        font-weight: 600
      &:hover
        border-color: var(--mainLight)

.text-input
  background: transparent
  border: none
  outline: none
  height: 44px
  &:disabled
    opacity: .75
  

.text-input.transparent
  border-bottom: 2px solid rgba(242, 101, 41, .3)
  padding-bottom: 8px
  font-size: 30px
  color: #f26529
  line-height: 1.3
  &::placeholder
    color: rgba(242, 101, 41, .3)
  &:focus
    border-color: #f26529
  & + &
    margin-top: 32px


.text-input.standard
  padding: 12px 20px
  border: 1px solid #d8d5de
  background: #f9f8fb
  border-radius: 4px
  min-width: 240px

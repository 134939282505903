
.labeled-field
  label
    display: block
    font-size: 24px
    color: #392964
    margin-bottom: 8px
  span[data-role="error"]
    color: #FC6535
    font-size: 12px
    margin-top: 8px
    margin-left: 8px
    display: inline-block


.text-area
  max-width: 100%
  width: 100% !important
  resize: none
  padding: 12px 20px
  border: 1px solid #d8d5de
  background: #f9f8fb
  border-radius: 4px
  outline: none
  line-height: 1.2
  height: 100px
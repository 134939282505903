

.header
  background: #8250FF
  color: #fff
  padding: 16px
  font-size: 18px
.body
  background: #fff
  padding: 16px
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px

.list
  th, td
    font-size: 10px !important
    letter-spacing: .05em
    font-weight: 600
    color: #6B7280
  th
    text-transform: uppercase
    svg
      right: 2px !important
  td, th
    padding: 24px 10px !important
    text-align: center !important
  thead tr
    border-bottom: 1px solid #000
  tr:hover
    td
      color: #fff !important
  .action
    color: #FC6535
    display: inline-flex
    width: 32px
    height: 32px
    font-size: 18px
    align-items: center
    justify-content: center
    transition: .15s
    border-radius: 50%
    cursor: pointer
    &:hover
      color: #fff
      background: #FC6535

.share-link-container
  display: flex
  align-items: center
  justify-content: center


.no-submissions
  padding: 40px
  display: flex
  justify-content: center
  align-items: center
  background: #fff
  font-size: 20px
  border-radius: 8px
  margin-top: 24px

.fit-tech-wrapper
  &[data-locked="true"]
    position: relative
    &::before
      position: absolute
      content: ''
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: #F9F8FBaa
      z-index: 5000
  &[data-locked="true"], &[data-locked="true"] *
    user-select: none
    pointer-events: none

.d-flex
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center

.flex
  display: flex
  justify-content: space-between
  margin-bottom: 30px

.day-counter-box
  width: 65%
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center
  .day-counter
    width: 50%
    height: 100%
    display: flex
    justify-content: flex-start
    align-items: center
    span
      color: #1C1C1C
      font-size: 24px
      font-style: normal
      font-weight: 550
      line-height: 24px
    .day-box
      width: 3rem
      height: 100%
      margin-right: 10px
      background: #FF6428
      border-radius: 12px
      display: flex
      justify-content: center
      align-items: center
      span
        color: #FBFBFB
        font-size: 24px
        font-style: normal
        font-weight: 600
        line-height: 24px


.top-bar
  h1
    font-size: 32px
    color: #1C1C1C
    font-weight: 600
    font-style: normal
    display: flex
    align-items: center
    margin-top: 2px
    line-height: 40px

.fit-tech-subscription
  position: relative
  display: flex
  padding: 18px
  padding-left: 40px
  // background: #8250FF
  background: #FF6428
  border-radius: 24px
  justify-content: space-between
  width: 40%
  .main
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-around
    .header
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      p
        color: #fff
        font-size: 18px
        & > span
          font-weight: 600
          font-size: 28px
          line-height: 20px
          margin-right: 10px
          span
            font-size: 38px
      button
        width: 110px
        align-self: unset
    .progress
      width: 90%
      p
        color: #fff
        font-weight: 600
        font-size: 9px
        line-height: 20px
        text-align: end
      &-bar
        height: 18px
        border-radius: 30px
        background: rgba(69, 68, 89, .3)
        display: flex
        justify-content: space-between
        align-items: center
        position: relative
        padding: 0 24px
        .dot
          width: 6px
          height: 6px
          background: rgb(69, 68, 89, .4)
          border-radius: 50%
        .bar
          position: absolute
          top: 0
          bottom: 0
          left: 0
          background: linear-gradient(180deg, #FFBAA5 0%, #FC6535 100%)
          border: 1px solid #FFFFFF
          border-radius: 30px
          transition: .15s
          max-width: 100%
          &[data-days-left="0"]
            display: none

  .date-range
    width: 28%
    display: flex
    flex-direction: column
    background: rgba(255, 255, 255, 0.2)
    border-radius: 8px
    color: #fff
    font-size: 14px
    line-height: 20px
    padding: 21px 24px
    .date
      display: flex
      span:first-child
        width: 30%
      &:first-child
        padding-bottom: 24px
      &:last-child
        padding-top: 24px
        border-top: 1px solid #fff
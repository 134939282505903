
.button
  display: inline-block
  padding: 12px
  background: #f26529
  color: #1a0902
  transition: .15s
  cursor: pointer
  border: none
  outline: none
  border-radius: 6px
  font-weight: 700
  align-self: flex-start
  font-size: 20px
  line-height: 28px
  margin-top: 12px
  text-align: center
  text-decoration: none
  &:not(:disabled):hover
    background: lighten(#f26529, 10%)
  svg
    position: relative
    top: 2px
    margin-left: 2px
  &.mainText
    color: #8250FF
  &.mainText:disabled
    opacity: .5
    cursor: default
  &.whiteText
    color: #fff
  &.nice, &.transparent, &.alter, &.alter-light
    color: #fff
    font-weight: normal
    font-size: 1rem
    width: 100%
    padding: 8px 12px
  &.transparent
    border: 1px solid #fff
    background: none
    &:hover
      background: #fff
      color: #333
  &.alter
    background: #392964
    &:hover
      background: #8250FF
  &.alter-light
    background: #8250FF
    &:hover
      background: #392964
  &.nice:disabled
    opacity: .5
    cursor: default


.top-bar
  h1
    font-size: 32px
    color: #392964
    font-weight: 400
    display: flex
    align-items: center
    svg
      padding: 2px
      border-radius: 50%
      box-sizing: content-box
      fill: #9CA3AF
      cursor: pointer
      transition: .15s
      margin-right: 16px
      user-select: none
      &:hover
        box-shadow: 0 0 5px #ccc
        fill: #f26529


.main-container
  display: flex
  main
    width: 100%
    padding: 0 24px
  .content
    background: #fff
    border-radius: 20px
    width: 100%
    padding: 12px
  &.hide-background
    .content
      background: none
aside
  border-right: 2px solid #edeced 
  height: 100vh
  background: #fff
@media (max-width: 420px)
  .main-container
    main
      padding: 0 16px
    .content
      padding: 0
      background: none
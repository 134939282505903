

.closed-question
  display: flex
  flex-direction: column
  font-family: 'Open Sans', sans-serif
  input
    width: 0
    height: 0
    &:checked + label
      border-color: #f26529
      box-shadow: 0 0 2px 1px #f26529
      svg
        opacity: 1
      span
          background: #f26529
          color: #fff !important
  label
    margin-bottom: 12px !important
    background: lighten(#f26529, 38%)
    border: 1px solid lighten(#f26529, 20%)
    width: 200px
    color: #f26529 !important
    border-radius: 4px
    padding: 4px 12px
    font-size: 20px !important
    cursor: pointer
    position: relative
    transition: .15s
    svg
      position: absolute
      width: 16px
      height: 16px
      top: 50%
      transform: translateY(-50%)
      right: 20px
      opacity: 0
      transition: .15s
      path
        fill: #f26529
    span
      display: inline-block
      position: relative !important
      padding: 2px
      border-radius: 2px
      background: #fff
      width: 20px
      height: 20px
      line-height: 1 !important
      border: 1px solid #f26529
      font-size: 14px !important
      text-align: center
      margin-right: 8px
      left: 0 !important
      top: -2px !important
      transition: .15s
      font-weight: 700
      text-transform: uppercase
    &:hover
      span
        background: #f26529
        color: #fff !important
.form
  display: flex
  flex-wrap: wrap
  align-items: center
  & > *
    width: 100%
    margin-top: 18px
  input
    width: 100%
  button
    width: 150px !important
    margin-top: 24px
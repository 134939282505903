

.popup
  position: absolute
  background: #fff
  max-width: 250px
  display: flex
  flex-direction: column
  align-items: center
  z-index: 1
  border-radius: 8px
  padding: 19px 24px 12px
  text-align: center
  h3
    color: #FC6535
    font-weight: 600
    font-size: 14px
    line-height: 16px
    margin: 0
    margin-bottom: 8px
  p
    color: #392B63
    font-weight: 400
    font-size: 12px
    line-height: 112%
    margin: 0
  span
    font-weight: 400
    font-size: 10px
    line-height: 16px
    color: rgba(57, 43, 99, 0.7)
    margin-top: 4px
    text-decoration: underline
    cursor: pointer
  &::before
    position: absolute
    content: ''
    height: 20px
    width: 20px
    top: -8px
    background: #fff
    border-radius: 4px
    left: 50%
    transform: translateX(-50%) rotate(45deg)
  button
    width: 90% !important
    align-self: center
.notActive
  max-width: 980px
  margin: 0 auto
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

.container#fit-tech
  max-width: 980px
  margin: 0 auto
  height: 100vh
  section, section form
    height: 100%
    max-height: 100% !important
  section form > div
    min-height: 100vh

.intro
  display: flex
  flex-direction: column
  padding-top: 100px
  align-items: center
  text-align: center
  font-family: 'Montserrat', sans-serif
  img
    width: 80%
  h1
    color: #8250FF
  p
    max-width: 600px
    font-size: 18px
    color: lighten(#8250FF, 10%)
  button
    align-self: center

@media (max-width: 420px)
  .intro
    padding: 80px 16px

.StaffGeeklogo
  width: 60% !important

.grid  

.grid22  
  height: 100% !important
  width: 50% !important
  
.grid33 
  height: 100% 
  width: 100%  

.blob
  display: flex
  position: fixed
  bottom: 0px
  width: 40%
  object-fit: contain


.gridform
  height: 100% !important
  width: 50% !important
  display: flex !important
  flex-direction: column !important 

.gridmain
  height: 100vh
  background: #fff !important
 
.box
  min-height: 100vh !important
  width: 100%
  background: #fff !important

.box 
  .grid 
    .grid 
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      
       
.typography
  text-align: start
  margin-top: 1rem !important
  font-weight: 700 !important
  line-height: 1.5rem !important
  font-family: 'Poppins', 'Public Sans', sans-serif !important 
  border-radius: 20px !important 

.typography2
  font-size: 1.5rem !important
  text-align: center !important
  border-radius: 4px !important 
  margin-top: 100px !important
  margin-bottom: 30px !important


.typography400
  text-align: start
  margin-bottom: 1rem !important
  font-weight: 400 !important
  line-height: 1.5rem !important
  font-family: 'Poppins', 'Public Sans', sans-serif !important
  border-radius: 4px !important 

.container
  margin-top: 2rem
  background-color: #fff
  color: rgb(33, 43, 54)

.textField
  margin-top: 1.5rem !important
   
.textFieldbtn
  margin-bottom: 1rem !important
  background: #787878

.boxMargin
  margin-top: 2rem  
 
.logo
  display: flex
  position: fixed
  margin: 41px auto

.grid44
  display: flex
  justify-content: center

.footer
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 25px

.divider
  display: flex
  align-items: center
  text-align: center 
  margin: auto
  width: 41%
  margin-top: 23px

.line 
  flex: 1
  height: 1px
  background-color: #ccc
  margin: 0 10px

.or 
  font-weight: bold
  color: #999
  font-size: 16px


@media screen and (max-width: 768px)
  .grid22 
    display: none

  .gridform
    width: 100% !important
    margin: auto !important
.checkbox
  label
    cursor: pointer
    position: initial !important
    span
      position: initial !important
  &-view
    width: 16px
    height: 16px
    background: #fdfdfd
    border: 1px solid #bcbcbc
    border-radius: 2px
    display: inline-flex
    margin-right: 12px
    margin-top: 12px
    position: relative
    transition: .1s
    align-items: center
    justify-content: center
    svg
      opacity: 0
      transition: .1s
  input:checked + .checkbox-view 
    background: #FC6535
    border-color: #FC6535
    svg
      opacity: 1
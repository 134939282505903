

body {
  background-color: #eff2f5;
  font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 768px){
  #root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.RegistrationScreen_grid__hAaxu.RegistrationScreen_gridform__rpNpd.css-1osj8n2-MuiGrid-root{
    min-width: 100%;
  }
  #root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.LoginScreen_grid__3ct9j.LoginScreen_gridform__jfB7C.css-1osj8n2-MuiGrid-root{
    min-width: 100%;
  }
  #root > div > div.MuiGrid-root.MuiGrid-container.VerifyemailScreen_grid__YqcpI.css-11lq3yg-MuiGrid-root > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.VerifyemailScreen_grid__YqcpI.VerifyemailScreen_gridform__wvj66.css-1osj8n2-MuiGrid-root{
    min-width: 100%;
  }
  #root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.RestorePasswordInitScreen_grid__Ryce6.RestorePasswordInitScreen_gridform__cKEvm.css-1osj8n2-MuiGrid-root{
    min-width: 100%;
  }
  #root > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.RestorePasswordConfirmScreen_grid__eml1y.RestorePasswordConfirmScreen_gridform__EWZMf.css-1osj8n2-MuiGrid-root{
    min-width: 100%;
  }
}
@keyframes shake {
  0% { transform: translate(.5px, .5px) rotate(0deg); }
  10% { transform: translate(-.5px, -.5px) rotate(-.5deg); }
  20% { transform: translate(-1.5px, 0px) rotate(.5deg); }
  30% { transform: translate(1.5px, 1px) rotate(0deg); }
  40% { transform: translate(.5px, -.5px) rotate(.5deg); }
  50% { transform: translate(-.5px, .5px) rotate(-.5deg); }
  60% { transform: translate(-1.5, .5px) rotate(0deg); }
  70% { transform: translate(1.5, .5px) rotate(-.5deg); }
  80% { transform: translate(-.5px, -.5px) rotate(.5deg); }
  90% { transform: translate(.5px, .5px) rotate(0deg); }
  100% { transform: translate(.5px, -.5px) rotate(-.5deg); }
}

a {
  color: #FC6535;
  text-decoration: none;
}

nav svg{
  max-width: 35px;
  max-height: 35px;
}


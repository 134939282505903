.list-controls
  margin-bottom: 24px
  display: flex
  align-items: flex-end
  & > *
    padding: 0
    margin-bottom: 0
    + *
      margin-left: 24px
  .search
    max-width: 324px
    width: 324px
    input::placeholder
      font-size: 16px
  .select-input
    min-width: 180px

.container
  background: #333
  height: 100vh
  display: flex
  justify-content: center
  font-family: 'Montserrat', sans-serif
  .content
    position: relative
    background: #FBFBFB
    max-width: 550px
    width: 550px
    margin-top: 22px
    margin-bottom: 22px
    padding: 30px
    border-radius: 32px
    & > .progress
      position: absolute
      top: 40px
      right: 12px

.card
  min-height: 25vh
  height: 45vh
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%)
  border-radius: 32px
  background: #fff
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  color: #8A8A8A
  padding: 24px 8px
  &:not(.no-drag)
    cursor: grab
    & > *
      pointer-events: none
  img
    width: 64px
    margin-bottom: 8px
  span
    color: #1C1C1C
    font-weight: bold
    letter-spacing: .2em
    font-size: 15px
  h1
    font-size: 28px
    color: black
    margin-top: 8px
    margin-bottom: 2px
    width: 90%
    text-align: center
    color: #8250FF
  .step
    color: #1C1C1C
    font-size: 13px
    font-weight: 600
    span
      color: #FF6428
      letter-spacing: unset
      font-size: 13px
      font-weight: 600
  .step-card
    border-radius: 20px
    height: 25vh
    width: 90%
    background: #D9D9D9
  p
    width: 90%
    color: #505861
    font-weight: 500
    text-align: center
    font-size: 13px
    line-height: 1.5
  label
    color: #1C1C1C
    width: 90%
    font-size: 14px
    text-align: start
    margin-bottom: 10px
    span
      color: #FF6428 
      font-size: 14px
  &.preview
    width: 250px
    height: 200px
    transform: scale(.5)
    transform-origin: top left


.card1
  min-height: 45vh
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%)
  border-radius: 32px
  background: #fff
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  color: #8A8A8A
  padding: 24px 8px
  &:not(.no-drag)
    cursor: grab
    & > *
      pointer-events: none
  img
    width: 64px
    margin-bottom: 8px
  span
    color: #1C1C1C
    font-weight: bold
    letter-spacing: .2em
    font-size: 15px
  h1
    font-size: 28px
    color: black
    margin-top: 8px
    margin-bottom: 2px
    width: 90%
    text-align: center
    color: #8250FF
  .step
    color: #1C1C1C
    font-size: 13px
    font-weight: 600
    span
      color: #FF6428
      letter-spacing: unset
      font-size: 13px
      font-weight: 600
  .step-card
    border-radius: 20px
    height: 25vh
    width: 90%
    background: #D9D9D9
  p
    width: 90%
    color: #505861
    font-weight: 500
    text-align: center
    font-size: 13px
    line-height: 1.5
  label
    color: #1C1C1C
    width: 90%
    font-size: 14px
    text-align: start
    margin-bottom: 10px
    span
      color: #FF6428 
      font-size: 14px
  &.preview
    width: 250px
    height: 135px
    transform: scale(.5)
    transform-origin: top left

.tip
  text-align: center
  color: #1C1C1C
  h3
    font-size: 16px
    margin-bottom: 8px
  p
    color: #505861
    margin-top: 0
    font-size: 14px
    line-height: 1.5

.hint
  color: #8593A3
  font-size: 12px
  text-align: center
  margin-top: 12px
  
.baskets
  display: flex
.basket
  height: 135px
  width: 100%
  background: #fff
  border-radius: 5px
  position: relative
  transition: .15s
  &-content
    height: 100%
    padding: 20px 5px 20px
    border: 3px solid #C3CCD6
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    color: #8250FF
    background: #fff
    border-radius: 12px
    text-align: center
    &.over
      border: 3px solid #f26529
    h3
      font-size: 16px
    span
      font-size: 10px
      font-weight: bold
      text-transform: uppercase
    .progress
      width: 100%
      span
        color: #505861
      .bar
        background: #E3E3E3
        height: 6px
        border-radius: 3px
        span
          max-width: 100%
          display: block
          height: 100%
          border-radius: 3px
          background: #8250FF
  &:not(:first-child)
    margin-left: 40px
  &:before
    position: absolute
    content: ''
    top: 0
    left: 0
    bottom: 0
    right: 0
    border-radius: inherit
    background: #000
    opacity: 0
    pointer-events: none

.basket-modal
  font-family: 'Montserrat', sans-serif
  align-items: stretch
  justify-content: space-between
  height: calc(100% - 15px)
  padding: 16px
  overflow-y: scroll
  .header
    display: flex
    align-items: center
    justify-content: space-around
    width: 100%
    .progress
      max-width: 90px
    h3
      font-size: 24px
      margin: 0
  .tip
    background: #8250FF
    width: 100%
    border-radius: 5px
    padding: 12px
    margin-top: 24px
    font-size: 12px
    font-weight: bold
    line-height: 1.1
  .attributes
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    margin: 24px -6px 0
    .attribute-container
      width: calc(100% / 3 - 12px)
      margin-bottom: 12px
      position: relative
      display: flex
      // transition: .15s
      margin: 0 6px 12px
      &.dragging
        width: 0
        overflow: hidden
        margin: 0
        opacity: 0
        pointer-events: none
      &.over:not(.last-in-row)
        width: calc( ( 100% / 3 - 12px ) * 2 + 12px )
        .attribute
          width: calc( 50% - 12px )
        &.left
          .attribute
            margin-left: calc(50% + 12px)
      .left
        position: absolute
        top: 0
        bottom: 0
        width: 50%
        z-index: 1
      .left
        left: 0
    .attribute
      box-shadow: 0px 0px 15px rgb(0 0 0 / 10%)
      position: relative
      height: 90px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      // transition: .15s
      width: 100%
      span
        font-size: 10px
        text-transform: none
      .order
        width: 32px
        height: 32px
        position: relative
        background: #8250FF
        border-radius: 50%
        color: #fff
        font-weight: bold
        margin-bottom: 8px
        span
          position: absolute
          transform: translate(-50%, -50%)
          top: 50%
          left: 50%
      .delete
        position: absolute
        width: 16px
        height: 16px
        font-size: 10px
        border-radius: 50%
        background: #eee
        color: #aaa
        top: -8px
        left: -8px
        cursor: pointer
        svg
          position: absolute
          transform: translate(-50%, -50%)
          top: 50%
          left: 50%

.final-form
  display: flex
  flex-direction: column
  align-items: stretch
  & > *:not(:first-child)
    margin-top: 12px

.final-form-content
  width: 100%
  max-width: 500px
.final-form-overlay
  position: fixed
  background: #000b
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  z-index: 1

.sort-essentials-content
  background: #fff
  max-width: 420px
  padding: 32px
  border-radius: 6px
  margin-right: 16px
  margin-left: 16px
  color: #8250FF
  font-weight: 600
  text-align: center

.progress-bar
  background: #EFF2F5
  border-radius: 12px
  margin-top: 24px
  height: 48px
  display: flex
  justify-content: center
  align-items: center
  position: relative
  color: #ABABAB
  font-weight: 600
  font-size: 14px
  overflow: hidden
  span
    z-index: 1
  .bar
    position: absolute
    height: 100%
    background: #8250FF
    left: 0

.basket-modal-content
  transform: none
  width: 420px
  position: absolute
  top: 0
  bottom: 40px
  outline: none
  .card
    cursor: auto

.basket-modal-overlay
  position: fixed
  inset: 0
  z-index: 1
  background: #0005
  
@media (max-width: 420px)
  .container
    padding: 16px
  .basket-modal-content
    left: 16px !important
    right: 16px !important
    transform: none !important
    width: auto
  .final-form
    padding: 16px

.drag-layer
  position: fixed
  top: 0
  left: 0
  z-index: 100
  pointer-events: none
  width: 100%
  height: 100%

.Howto
  color: #8250FF
  
.text-input
  background: transparent 
  outline: none
  height: 50px
  &:disabled
    opacity: .75 
  padding: 12px 20px
  border: 1px solid #d8d5de 
  border-radius: 12px
  width: 90%
  margin-bottom: 12px